// Site home page

import React from "react"

import { Layout, Link, Button, ContentWrapper } from "../components"
import css from "./index.module.sass"


function _Product({ title, link, img, alt, first, last, children}) {
	let order
	if (first) {
		order = css.first
	} else if (last) {
		order = css.last
	}
	return (
		<article className={`${css.card} ${order}`}>
			<Link to={link}>
				<img src={img} alt={alt} />
				<div className={css.text}>
					<header>
						<h2 className={css.title}>{title}</h2>
					</header>
					<div className={css.description}>
						{children}
					</div>
					<Button to={link} label="Learn More" />
				</div>
			</Link>
		</article>
	)
}


export default function HomePage({ location }) {
	return (
		<Layout
			pageTitle="Home"
			// Passing location on to style nav menu
			location={location}
		>

			<ContentWrapper style={{textAlign:`center`}}>
				<img className={css.thanks_img} src="/images/thanks.jpg" alt="An Aureus International display booth at a convention." />
				<h2>Thank You to All Those That Serve</h2>
				<p>Dear First Responders, we just want to say Thank You! Thank you for what you do every day to protect us and keep us safe and for going the extra mile in these unpredictable and unprecedented times! Thank you for giving us some sense of peace knowing that you are continuing to work and doing what you do best. We pray you remain healthy, safe and appreciated!</p>
			</ContentWrapper>

			{/* Features */}
			<section className={css.products_container}>
				<div className={css.product_row}>
					<_Product
						title="Medic"
						link="/medic"
						img="/images/featured_medic1.jpg"
						alt="Blue aviator flight suit on a white background"
						first
					>
						<p>This suit is designed for the Air Medical personnel, by adding front hand pockets and a nurse pocket on the right leg. You can get the model design in a one color or a two-tone with the Medic II. All leg pockets are positioned on the outside of the legs for easy access to your supplies. This suit has the pockets that are needed so you can put your attention to the patient.</p>
					</_Product>

					<_Product
						title="Medic II"
						link="/medicII"
						img="/images/featured_medic2.jpg"
						alt="Two-tone black and grey flight suite with grey on top and black on botton in front of a white background."
						last
					>
						<p>When you want a suit that will give you that unique look and set you apart from others, our two -tone Medic II is for you. This suit is designed like the Medic put in a two color combination. Just tell us the color combination that fits your program plus any other decoration that you need.</p>
					</_Product>
				</div>

				<div className={css.product_row}>
					<_Product
						title="Aviator"
						link="/aviator"
						img="/images/featured_aviator.jpg"
						alt="Blue aviator flight suite with a white background."
						first
					>
						<p>Our bestselling flight suit is available in poly / cotton and fire resistant Nomex and is like the military style suit. We have manufactured these for the Air Medical rescue personnel, law enforcement, United States Coast Guard, and others.</p>
					</_Product>

					<_Product
						title="Defender X"
						link="/defenderx"
						img="/images/featured_defender_x.jpg"
						alt="Black jacket with an American flag on the sleeve and a white background."
						last
					>
						<p>We offer a Nomex jacket to match you uniform: color, striping, and decoration. You can decide between three different weights of jackets, The basic light weight Nomex jackets or add a Nomex Fleece linear. We offer the full linear as permanent or a zip-out linear. Then you have the option of a zip-out Nomex fleece Vest. With all these options, you have a decision to make.</p>
					</_Product>
				</div>

			</section>

		</Layout>
	)
}
